import React from 'react';
import HeroBanner from './HeroBanner';
import Navbar from './Navbar';
import Message from './Message';
import MessageForm from './MessageForm';

const Home = () => {
  return (
    <div className='relative'>
      <HeroBanner />
      <Navbar />
      <Message open={350} closed={70} child={<MessageForm />} classes={"fixed"} icon={true} />
    </div>
  )
}
export default Home;