import React from 'react';
import { FaFacebookF, FaInstagram, FaTwitter, FaPinterest } from "react-icons/fa";
import { motion } from 'framer-motion';

const MenuFooter = () => {
  const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.8 }
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  };

  const variant = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  };

  return (
    <motion.div 
      className='menufooter flex flex-col w-full h-fit justify-around px-[25px] font-semibold'
      variants={variants}
    >
      <motion.p variants={variant}>LOGIN</motion.p>
      <motion.p variants={variant}>GET STARTED</motion.p>
      <motion.p variants={variant}>HELP</motion.p>
      <motion.div variants={variant} className='flex flex-row justify-between gap-[80px] text-[20px] py-[20px]'>
        <FaFacebookF />
        <FaInstagram />
        <FaTwitter />
        <FaPinterest />
      </motion.div>
    </motion.div>
  )
}

export default MenuFooter;