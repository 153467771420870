import React, { useRef } from "react";
import { motion, useCycle } from "framer-motion";

import { useDimensions } from "./use-dimensons.js"
import MenuToggle from "./MenuToggle.jsx";

const nav = {
  open: {
    height: '350px',
    width: '350px',
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    },
  },
  closed: {
    height: '70px',
    width: '70px',
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};

const Message = ({open, closed, child, classes, icon, position}) => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  const sidebar = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
      height: `${open}px`,
      width: `${open}px`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2
      }
    }),
    closed: {
      clipPath: "circle(30px at 32px 32px)",
      height: `${closed}px`,
      width: `${closed}px`,
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40
      }
    }
  };

  return (
    <motion.div
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
      variants={nav}
      className={`nav ${classes}`}
    >
      <motion.div className="background shadow-inner" variants={sidebar} />
      {/* <Navigation /> */}
      {child}
      <MenuToggle position={position} icon={icon} toggle={() => toggleOpen()} />
    </motion.div>
  );
}

export default Message;