import React from 'react';
import { motion } from 'framer-motion';

const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
);

const MenuButton = ({ isOpen, toggle }) => {
  return (
    <motion.div 
          className='w-fit h-fit mr-[20px] lg:hidden mt-[10px] md:mr-[25px]'
          initial={false}
          animate={isOpen ? "open" : "closed"}
        >
          <button className='w-fit h-fit static' onClick={()=>toggle()}>
            <svg width="23" height="23" viewBox="0 0 23 23">
              <Path 
                variants={{
                  closed: { d: "M 2 2.5 L 20 2.5" },
                  open: { d: "M 3 16.5 L 17 2.5" }
                }} 
              />

              <Path
                d="M 2 9.423 L 20 9.423"
                variants={{
                  closed: { opacity: 1 },
                  open: { opacity: 0 }
                }}
                transition={{ duration: 0.1 }}
              />
              
              <Path
                variants={{
                  closed: { d: "M 2 16.346 L 20 16.346" },
                  open: { d: "M 3 2.5 L 17 16.346" }
                }}
              />
            </svg>
          </button>
        </motion.div>
  )
}

export default MenuButton;