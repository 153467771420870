import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import Navigation from './Navigation';
import MenuFooter from './MenuFooter';

const Menu = ({ isOpen }) => {

  const itemIds = [0, 1, 2, 3, 4];
  const menuContent = [
    {key:"HOW IT WORKS", value:["Choose a Stylist", "Choose a Meet"]},
    {key: "PORTFOLIO", value: ["HELLO"]},
    {key: "TESTIMONIALS", value: []},
    {key: "SHOP", value: []},
    {key: "CONTACT US", value: []}
  ];

  return (
    <AnimatePresence>
      <motion.div className='flex flex-col fixed w-full right-0 top-[80px] md:w-1/2 bg-[#ede7df] lg:hidden rounded-sm z-10 overflow-y-auto overscroll-contain hide-scrollbar shadow-inner'
        style={{ height: "calc(100% - 80px"}}
        animate={ isOpen ? "open" : "closed" }
        initial={{ x: "100%" }}
        variants={{ 
          open: { x: 0, transition: { type: "spring" } },
          closed: { x: "100%", transition: { when: "afterChildren" } }
        }}
        transition={{ duration: 0.5 }}
      >
        <Navigation itemIds={itemIds} menuContent={menuContent} />
        <motion.div
          className='w-full h-fit'
          variants = {{
            open: {
              transition: { staggerChildren: 0.07, delayChildren: 0.8 }
            },
            closed: {
              transition: { staggerChildren: 0.05, staggerDirection: -1 }
            }
          }}
        >
          <motion.hr
            className='w-full h-[5px] bg-[#e59400]'
            variants={{
              open: {
                y: 0,
                opacity: 1,
                transition: {
                  y: { stiffness: 1000, velocity: -100 }
                }
              },
              closed: {
                y: 50,
                opacity: 0,
                transition: {
                  y: { stiffness: 1000 }
                }
              }
            }}
          />
        </motion.div>
        <MenuFooter />
      </motion.div>
    </AnimatePresence>
  )
}

export default Menu;