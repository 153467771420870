import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { home_1, home_2, home_3 } from '../assets';
import Slider from 'react-slick';
import ImageDiv from './ImageDiv';

const HeroBanner = () => {
  const images = [
    home_1,
    home_2,
    home_3,
  ]

  const settings = {
    // className: "test",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 10000,
    cssEase: "linear",
  };


  return (
    <section>
      <div className='flex-col md:relative flex items-center h-full overflow-x-hidden'>
        <div className='w-full relative h-fit'>
          <Slider {...settings}>
            {
              images.map((image, index) => <ImageDiv img={image} key={index} />)
            }
          </Slider>
        </div>
        <div className='static flex flex-col items-center bg-[#ede7df] px-[30px] font-serif rounded-sm gap-10 w-full md:absolute md:items-start md:w-fit md:top-[20%] lg:top-[30%] left-[10%]'>
            <div className='flex mt-[-10px] md:mt-[-22px] md:flex-col relative'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 55" fill="none" className='w-[50px] h=[50px] hidden md:block md:w-[120px] md:h-[120px]'><path d="M0 0h44l7.5 10h-44L0 0Z" fill="#C2B04A"></path><path d="M0 55V0h44v55L22.473 43.246 0 55Z" fill="#DECE71"></path><path d="M22.8 32h7.344v-1.007l-1.198-.111c-1.144-.112-1.438-.754-1.438-2.04V12h-.745c-.718 1.256-1.97 2.207-3.78 2.82l.133.921c.639-.167 1.41-.39 1.782-.39.48 0 .613.279.613 1.089v12.4c0 1.285-.372 1.928-1.596 2.04l-1.118.11v1.007l.002.003ZM16.208 22.05h3.299l-.602 4.48h-3.299l.602-4.48ZM18.169 32h.38l.67-5.082h2.34v-.385h-2.272l.601-4.48h2.295v-.386h-2.252l.625-4.505h-.38l-.601 4.505h-3.322l.625-4.505h-.38l-.601 4.505h-2.273v.385h2.207l-.601 4.481H13v.385h2.184L14.494 32h.38l.669-5.082h3.322L18.174 32h-.006Z" transform="translate(0, -8)" fill="#362B24"></path></svg>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 55" fill="none" className='w-[50px] h=[50px] md:hidden'><path d="M0 0h44l7.5 10h-44L0 0Z" fill="#C2B04A"></path><path d="M0 55V0h44v55L22.473 43.246 0 55Z" fill="#DECE71"></path><path d="M22.8 32h7.344v-1.007l-1.198-.111c-1.144-.112-1.438-.754-1.438-2.04V12h-.745c-.718 1.256-1.97 2.207-3.78 2.82l.133.921c.639-.167 1.41-.39 1.782-.39.48 0 .613.279.613 1.089v12.4c0 1.285-.372 1.928-1.596 2.04l-1.118.11v1.007l.002.003ZM16.208 22.05h3.299l-.602 4.48h-3.299l.602-4.48ZM18.169 32h.38l.67-5.082h2.34v-.385h-2.272l.601-4.48h2.295v-.386h-2.252l.625-4.505h-.38l-.601 4.505h-3.322l.625-4.505h-.38l-.601 4.505h-2.273v.385h2.207l-.601 4.481H13v.385h2.184L14.494 32h.38l.669-5.082h3.322L18.174 32h-.006Z" transform="translate(0, -1.5)" fill="#362B24"></path></svg>
              <span className='text-[11px] mt-[20px] ml-[15px] md:absolute md:bottom-[30px] md:left-[10px] md:text-center md:text-[9px] md:ml-0 md:w-[84px]'>INTERIOR DESIGN SERVICE</span>
            </div>
            <div className='w-[100%] text-center md:text-left md:w-[450px]'>
              <h1 className='text-[30px] text-black lg:text-[45px]'>Stunning interior design services, now within reach</h1>
            </div>
            <Link to={'/get-started'}
              className='mb-8 bg-[#e59400] w-fit p-4 px-8 rounded-[50px] text-[14px] font-semibold'
            >FIND YOUR STYLE</Link>
        </div>
      </div>
    </section>
  )
}

export default HeroBanner;