import React from 'react';
import { Link } from 'react-router-dom';
import { useCycle } from 'framer-motion';

import { logo } from '../assets';
// import Login from './Login';
import MenuButton from './MenuButton';
import Menu from './Menu';

const Navbar = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);

  return (
    <div>
      <nav className={`fixed top-0 p-0 md:px-10 flex flex-row h-[80px] w-full items-center gap-3 justify-between transition duration-500 hover:backdrop-blur-sm hover:ease-in-out hover:shadow-lg ${isOpen ? "backdrop-blur-sm" : ""}`}>
        <div className='w-[25%] lg:w-[10%] flex flex-col items-start'>
          <img src={logo} alt="logo" className='w-24' />
        </div>
        <div className='hidden lg:w-[45%] lg:m-0 lg:p-0 lg:flex lg:flex-row lg:justify-start lg:gap-[30px]'>
          <Link to={'/'} className='group flex flex-col items-center'>
            <div className='bg-transparent w-[5px] h-[5px] rounded-full mb-[5px] group-hover:bg-[#e59400]' />
            HOW IT WORKS
          </Link>
          <Link to={'/'} className='group flex flex-col items-center'>
            <div className='bg-transparent w-[5px] h-[5px] rounded-full mb-[5px] group-hover:bg-[#e59400]' />
            PORTFOLIO
          </Link>
          <Link to={'/'} className='group flex flex-col items-center'>
            <div className='bg-transparent w-[5px] h-[5px] rounded-full mb-[5px] group-hover:bg-[#e59400]' />
            SHOP
          </Link>
        </div>
        <div className='hidden lg:flex lg:flex-row lg:w-[45%] lg:m-0 lg:p-0 lg:justify-end lg:gap-10 lg:font-semibold'>
          <Link to={'/login'} className='py-2 px-14 border-2 border-[#e59400] rounded-3xl hover:border-[#304561] hover:bg-[#304561] hover:text-white'>LOGIN</Link>
          <Link to={'/get-started'} className='bg-[#e59400] py-2 px-8 rounded-3xl hover:bg-[#304561] hover:text-white'>GET STARTED</Link>
          {/* <input className='bg-[#e59400] py-2 px-8 rounded-3xl' type="button" value="GET STARTED" /> */}
        </div>

        <MenuButton isOpen={isOpen} toggle={() => toggleOpen()} />
      </nav>

      <Menu isOpen={isOpen} />
    </div>
  )
}

export default Navbar;