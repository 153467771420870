import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ComingSoon from './components/ComingSoon';
import Home from './components/Home';
import GetStarted from './components/GetStarted';
import Login from './components/Login';

const App = () => {
  return (
    <BrowserRouter>
      <div className='relative'>
        {/* <ComingSoon /> */}
        <Routes>
          <Route path='/*' Component={Home} />
          <Route exact path='/get-started' Component={GetStarted} />
          <Route path='/login' Component={Login} />
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App;