import React from 'react';
import { motion } from "framer-motion";
import MenuItem from './MenuItem';


const Navigation = ({ menuContent }) => {
  const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.8 }
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  };
 
  return (
    <motion.ul variants={variants}>
      {menuContent.map((menu, i) => (<MenuItem menu={menu.key || menu} i={i} key={i} submenu={menu.value || []} />
      ))}
    </motion.ul>
  );
}

export default Navigation;