import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import { home_1, home_2, home_3} from '../assets';
import ImageDiv from './ImageDiv';

const GetStarted = () => {
  const settings = {
    // className: "test",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 10000,
    cssEase: "linear",
  };

  const images = [
    home_1,
    home_2,
    home_3,
  ];

  const numbers = [1, 2, 3, 4, 5, 6];
  return (
    // <div className="slider-div">
      <Slider {...settings}>
        {
          images.map(image => <ImageDiv img={image} />)
        }
      </Slider>
    // </div>
  )
}

export default GetStarted;