import React from 'react';
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { motion, useCycle } from "framer-motion";

import Navigation from './Navigation';

const MenuItem = ({ i, menu, submenu }) => {
  const [isOpen, toggleSubmenu] = useCycle(false, true);
  const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  };

  const colors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF"];

  const style = { border: `2px solid ${colors[i]}` };
  return (
    <div>
      <motion.li
        variants={variants}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 1 }}
        onClick={() => {console.log("open submenu");submenu.length && toggleSubmenu()}}
        className='justify-between'
      >
        <div className='flex'>
          <div className="icon-placeholder" style={style} />
          <div className="text-placeholder flex justify-start items-center p-[20px]" style={style}>
            <p className='w-auto font-semibold'>{ menu }</p>
          </div>
        </div>
        
        {
          submenu.length ? (
            <>
              <AiOutlineMinus className={`${isOpen ? "block":"hidden"}`} />
              <AiOutlinePlus className={`${isOpen ? "hidden":"block"}`} />
            </>
          ) : <></>
        }
        
      </motion.li>
      {isOpen &&
        submenu.length ? 
        <Navigation menuContent={submenu} /> : <></>
      }
    </div>
  )
};

export default MenuItem;