import React from 'react';
import { motion } from "framer-motion";

const variants = {
  open: {
    display: 'block',
    transition: { delay: 0.5, staggerChildren: 0.07, delayChildren: 1.9 }
  },
  closed: {
    display: 'none',
    transition: { delay: 0.9, staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const variant = {
  open: {
    y: 0,
    display: 'flex',
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    },
  },
  closed: {
    y: 20,
    display: 'none',
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

const MessageForm = () => {
  return (
    <motion.form style={{ position: 'absolute', width: '90%' }} variants={variants} onSubmit={() => {}}>
        <motion.p className="mb-2 justify-center" variants={variant}>Drop A Message</motion.p>
        <motion.div className="w-full mb-[10px]" variants={variant}>
          <input className="w-full bg-slate-400 rounded p-[5px] text-white outline-none shadow-[0_0_25px_rgba(148,163,184,0.2)]" type="email" placeholder="email" />
        </motion.div>
        <motion.div className="w-full mb-[10px]" variants={variant}>
          <input className="w-full bg-slate-400 rounded p-[5px] text-white outline-none shadow-[0_0_25px_rgba(148,163,184,0.2)]" type="name" placeholder="name" />
        </motion.div>
        <motion.div className="w-full mb-[5px]" variants={variant}>
          <motion.textarea rows={5} className="w-full bg-slate-400 text-white rounded p-[5px] outline-none shadow-[0_0_25px_rgba(148,163,184,0.2)]" name="message" placeholder="message" />
        </motion.div>
        <motion.div id="messageBtn" className="flex w-full justify-center align-middle" variants={variant}>
          <button type="submit" className="bg-black rounded-md w-auto text-white hover:bg-zinc-800">Submit</button>
        </motion.div>
      </motion.form>
  )
}

export default MessageForm;