import React from 'react';

const ImageDiv = ({ img }) => {
  return (
    <div 
    className='mr-0 w-full md:w-[98%] lg:mr-[10px]'
    ><img src={img} alt="img" className='w-full h-[380px] md:h-[510px] lg:h-screen' /></div>
  )
}

export default ImageDiv;